import { Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GetVisitById, PaymentStatusEnum } from 'woofwoof-api';
import { useProfilesContext } from '../../profile/hooks/useProfilesContext';
import { useVisitsContext } from '../hooks/useVisitsContext';
import CancelVisitModal from './CancelVisitModal';
import {
  NUMBER_OF_HOURS_BEFORE_LOCK,
  isTimeEqualOrPast,
} from './UserUpcomingVisitCard';

type UpcomingVisitsDetailsModalActionsProps = {
  visit: GetVisitById;
  onClose: () => void;
};

let isVisitCancelOrRescheduleAvailableInterval: ReturnType<typeof setInterval>;
export default function UpcomingVisitsDetailsModalActions({
  visit,
  onClose,
}: UpcomingVisitsDetailsModalActionsProps) {
  const navigate = useNavigate();
  const { refetch: refetchProfiles } = useProfilesContext();
  const { refetch: refetchVisits } = useVisitsContext();
  const [isRescheduleOrCancelAvailable, setIsRescheduleOrCancelAvailable] =
    useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const isPaymentFailed = visit?.payment?.status === PaymentStatusEnum.Failed;
  const isPaymentPending = visit?.payment?.status === PaymentStatusEnum.Created;

  useEffect(() => {
    if (visit?.startTime) {
      const visitTime = new Date(visit.startTime);
      const date = new Date(
        visitTime.setHours(visitTime.getHours() - NUMBER_OF_HOURS_BEFORE_LOCK),
      ).toISOString();
      const _isRescheduleOrCancelAvailable = !isTimeEqualOrPast(date);
      if (!isRescheduleOrCancelAvailable && _isRescheduleOrCancelAvailable) {
        setIsRescheduleOrCancelAvailable(true);
      }
    }
  }, [isRescheduleOrCancelAvailable, visit?.startTime]);

  useEffect(() => {
    if (visit?.startTime) {
      isVisitCancelOrRescheduleAvailableInterval = setInterval(() => {
        const visitTime = new Date(visit.startTime);
        const date = new Date(
          visitTime.setHours(
            visitTime.getHours() - NUMBER_OF_HOURS_BEFORE_LOCK,
          ),
        ).toISOString();
        const _isRescheduleOrCancelAvailable = !isTimeEqualOrPast(date);
        if (!isRescheduleOrCancelAvailable && _isRescheduleOrCancelAvailable) {
          setIsRescheduleOrCancelAvailable(true);
        }
      }, 10000);
    }

    return () => {
      clearInterval(isVisitCancelOrRescheduleAvailableInterval);
    };
  }, [isRescheduleOrCancelAvailable, visit?.startTime]);

  const handleOnCancelVisitClick = () => setCancelModalOpen(true);

  const handleOnReschedulelVisitClick = () =>
    visit.profile?.id &&
    navigate(
      PATH_DASHBOARD.general.booking.reschedule(visit.profile?.id, visit.id),
    );

  const handleCancelVisitRefetch = () => {
    onClose();
    refetchVisits();
    refetchProfiles();
  };

  const handleRenewPayment = () => {
    if (isPaymentFailed && visit?.payment?.paymentUrl) {
      return window.location.replace(visit.payment.paymentUrl);
    }
  };

  return (
    <>
      <Stack sx={{ gap: 2, flexDirection: 'row' }}>
        <Button
          variant="soft"
          color="inherit"
          startIcon={<Iconify icon="fe:close" />}
          onClick={handleOnCancelVisitClick}
          disabled={
            isPaymentFailed
              ? false
              : isPaymentPending || !isRescheduleOrCancelAvailable
          }
        >
          Anuluj e-spotkanie
        </Button>
        {isPaymentFailed ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Iconify icon="eva:shopping-cart-fill" />}
            onClick={handleRenewPayment}
          >
            Ponów płatność
          </Button>
        ) : (
          <Button
            variant="soft"
            color="inherit"
            startIcon={<Iconify icon="eva:calendar-fill" />}
            onClick={handleOnReschedulelVisitClick}
            disabled={
              isPaymentFailed ||
              isPaymentPending ||
              !isRescheduleOrCancelAvailable
            }
          >
            Zmień termin
          </Button>
        )}
      </Stack>
      <CancelVisitModal
        isOpen={isCancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        visit={visit}
        refetch={handleCancelVisitRefetch}
      />
    </>
  );
}
