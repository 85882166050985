// i18n
import './locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { plPL } from '@mui/x-date-pickers/locales';
// redux
import { persistor, store } from './redux/store';
// routes
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import pl from 'date-fns/locale/pl';
import { MotionLazyContainer } from './components/animate';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from './auth/FirebaseContext';
import { UserProvider } from './auth/UserContext';
import Router from './routes';

// ----------------------------------------------------------------------
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UserProvider>
          <HelmetProvider>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={pl}
                  localeText={
                    plPL.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <SettingsProvider>
                    <BrowserRouter>
                      <ScrollToTop />
                      <MotionLazyContainer>
                        <ThemeProvider>
                          <ThemeSettings>
                            <ThemeLocalization>
                              <SnackbarProvider>
                                <StyledChart />
                                <Router />
                              </SnackbarProvider>
                            </ThemeLocalization>
                          </ThemeSettings>
                        </ThemeProvider>
                      </MotionLazyContainer>
                    </BrowserRouter>
                  </SettingsProvider>
                </LocalizationProvider>
              </PersistGate>
            </ReduxProvider>
          </HelmetProvider>
        </UserProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
